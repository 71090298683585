import { Vehicle } from '../../types/vehicle';
import { isVehicleActive } from '../../utils/vehicleUtils';
import { CarIcon } from '../icons/CarIcon';
import { VanIcon } from '../icons/VanIcon';
import Tooltip from '../ui/Tooltip';
import { formatLastUpdate } from '../../utils/vehicleUtils';

interface VehicleMarkerProps {
  vehicle: Vehicle;
}

export default function VehicleMarker({ vehicle }: VehicleMarkerProps) {
  const VehicleIcon = vehicle.type === 'golf-cart' ? CarIcon : VanIcon;
  const isActive = isVehicleActive(vehicle.lastUpdate);
  const lastUpdateTime = formatLastUpdate(vehicle.lastUpdate);

  return (
    <div className={`
      relative group cursor-pointer
      ${isActive ? 'z-30' : 'z-20'} 
    `}>
      <Tooltip text={
        <div className="space-y-1.5">
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Vehicle:</span> {vehicle.name}
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Route:</span> {vehicle.route}
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Plate:</span> {vehicle.plate}
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Speed:</span> {vehicle.mph} MPH
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Last Update:</span> {lastUpdateTime}
          </p>
          {!isActive && (
            <p className="text-sm font-medium text-red-600 mt-2">
              Vehicle Currently Inactive
            </p>
          )}
        </div>
      }>
        <div className="relative">
          <div className="absolute -inset-3 bg-black/5 rounded-full blur-md group-hover:bg-black/10 
                        transition-all duration-200"></div>
          <div className={`
            bg-white rounded-full p-2.5 shadow-lg transform 
            transition-all duration-300
            ${isActive ? 'group-hover:scale-105' : 'opacity-75 group-hover:opacity-100'}
          `}>
            <VehicleIcon 
              className="h-7 w-7"
              style={{ 
                color: isActive ? vehicle.color : '#9CA3AF',
                filter: isActive ? 'none' : 'grayscale(100%)'
              }}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}
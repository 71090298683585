import { ReactNode } from 'react';

interface TooltipProps {
  children: ReactNode;
  text: ReactNode;
  isOpen?: boolean;
}

export default function Tooltip({ children, text, isOpen = false }: TooltipProps) {
  const baseClasses = `
    absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full 
    bg-white rounded-lg px-4 py-3 shadow-lg
    transition-all duration-200
    pointer-events-none z-[9999]
    min-w-[220px]
    border border-gray-100
  `;

  const visibilityClasses = isOpen 
    ? 'opacity-100 translate-y-0' 
    : 'opacity-0 translate-y-1 group-hover:opacity-100 group-hover:translate-y-0';

  return (
    <div className="relative group">
      <div className={`${baseClasses} ${visibilityClasses}`}>
        {text}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 
                      rotate-45 w-2.5 h-2.5 bg-white border-r border-b border-gray-100">
        </div>
      </div>
      {children}
    </div>
  );
}
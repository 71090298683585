import { ReactNode, useState } from 'react';
import { X } from 'lucide-react';

interface CloseableTooltipProps {
  children: ReactNode;
  text: ReactNode;
  defaultOpen?: boolean;
}

export default function CloseableTooltip({ children, text, defaultOpen = false }: CloseableTooltipProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const baseClasses = `
    absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full 
    bg-white rounded-lg px-4 py-3 shadow-lg
    transition-all duration-300
    z-[9999]
    min-w-[180px]
    border border-gray-100
  `;

  const visibilityClasses = isOpen 
    ? 'opacity-100 translate-y-0' 
    : 'opacity-0 translate-y-2 pointer-events-none';

  const handleMarkerClick = () => {
    if (!isOpen) setIsOpen(true);
  };

  return (
    <div className="relative" onClick={handleMarkerClick}>
      <div className={`${baseClasses} ${visibilityClasses}`}>
        <div className="flex justify-between items-start gap-3">
          <div className="text-sm font-medium text-gray-900">{text}</div>
          <button 
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
            className="p-0.5 hover:bg-gray-100 rounded-full transition-colors -mt-0.5"
          >
            <X className="h-4 w-4 text-gray-500" />
          </button>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 
                      rotate-45 w-2.5 h-2.5 bg-white border-r border-b border-gray-100">
        </div>
      </div>
      {children}
    </div>
  );
}
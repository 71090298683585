import { SVGProps } from 'react';

export function VanIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg 
      viewBox="0 0 390.271 390.271" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        d="M338.23 189.705H47.774v8.145h18.424c10.279 0 19.523 4.848 25.471 12.994l13.576 18.941c9.244 13.576.517 32-15.709 32H47.774v50.941h290.521v-50.941h-41.762c-16.808-.517-24.954-20.04-15.709-32l13.576-18.941c5.947-8.145 15.709-12.994 25.471-12.994h18.424v-8.145z"
        fill="currentColor"
      />
      <path 
        d="M379.41 146.327c-5.947 0-10.861 4.848-10.861 10.861v10.861h-10.279L335.515 56.404V32.549c0-17.907-14.61-32.517-32.517-32.517H83.523c-17.907 0-32.517 14.61-32.517 32.517v23.855L28.251 168.048h-6.529v-10.861c0-5.947-4.848-10.861-10.861-10.861S0 151.176 0 157.188v21.657c0 5.947 4.848 10.861 10.861 10.861h15.192v133.883c0 5.947 4.848 10.861 10.861 10.861h24.954v44.929c0 5.947 4.848 10.861 10.861 10.861h49.842c5.947 0 10.861-4.848 10.861-10.861v-44.994h119.208v44.994c0 5.947 4.848 10.861 10.861 10.861h49.842c5.947 0 10.861-4.848 10.861-10.861v-44.994h24.954c5.948 0 10.861-4.848 10.861-10.861V189.705h19.394c5.947 0 10.861-4.848 10.861-10.861v-21.657c-.001-5.947-4.85-10.86-8.862-10.86z"
        fill="currentColor"
      />
    </svg>
  );
}
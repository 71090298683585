import axios from 'axios';
import { API_CONFIG } from '../config/api';
import { VehicleLocation } from '../types/vehicle';

const api = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export interface ApiResponse<T> {
  statusCode: number;
  body: T;
}

export async function fetchVehicleLocations(): Promise<VehicleLocation[]> {
  const response = await api.get<ApiResponse<VehicleLocation[]>>(API_CONFIG.ENDPOINTS.LOCATION);
  
  if (response.data.statusCode !== 200 || !Array.isArray(response.data.body)) {
    throw new Error('Invalid response format from vehicle location API');
  }
  
  return response.data.body;
}
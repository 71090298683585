import { AxiosError } from 'axios';

export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  return 'An unknown error occurred';
}

export function formatApiError(error: unknown): string {
  if (error instanceof Error) {
    // Handle network errors
    if ('isAxiosError' in error) {
      const axiosError = error as AxiosError;
      if (!axiosError.response) {
        return 'Network error: Unable to reach the server';
      }
      return `Server error: ${axiosError.response.status}`;
    }
    return error.message;
  }
  return 'An unknown error occurred while fetching data';
}
import { VehicleInfo } from '../types/vehicle';

export const VEHICLES_INFO: Record<string, VehicleInfo> = {
  '864035052382927': {
    route: 'Old San Juan Express',
    name: 'Ford Transit 821',
    color: '#2563eb', // Blue for vans
    plate: 'KFW821',
    type: 'van',
    serialNumber: '1FBVU4XG9PK75306'
  },
  '864035052792497': {
    route: 'Condado Shuttle',
    name: 'Chevrolet Express 711',
    color: '#2563eb', // Blue for vans
    plate: 'KGS711',
    type: 'van',
    serialNumber: '1GAZGNFP9P1181388'
  },
  '864035051785161': {
    route: 'Plaza Route',
    name: 'Garita 818',
    color: '#16a34a', // Green for golf carts
    plate: 'JSB818',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CACG3199247'
  },
  '864035052792299': {
    route: 'Cathedral Circuit',
    name: 'Garita 111',
    color: '#16a34a', // Green for golf carts
    plate: 'IGG111',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CAVJ3361751'
  },
  '864035052374221': {
    route: 'Castillo Express',
    name: 'Garita 110',
    color: '#16a34a', // Green for golf carts
    plate: 'IGG110',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CACJ3361750'
  },
  '864035052792398': {
    route: 'Old San Juan Historic Loop',
    name: 'Garita 109',
    color: '#16a34a', // Green for golf carts
    plate: 'IGG109',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CAEEJ3365160'
  }
};
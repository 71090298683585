import { Vehicle } from '../types/vehicle';

const INACTIVE_THRESHOLD = 10 * 60 * 1000; // 10 minutes in milliseconds

export function isVehicleActive(lastUpdate: string): boolean {
  const lastUpdateTime = new Date(lastUpdate).getTime();
  const currentTime = new Date().getTime();
  return currentTime - lastUpdateTime < INACTIVE_THRESHOLD;
}

export function formatLastUpdate(lastUpdate: string): string {
  return new Date(lastUpdate).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function sortVehiclesByStatus(vehicles: Vehicle[]): Vehicle[] {
  return [...vehicles].sort((a, b) => {
    const aActive = isVehicleActive(a.lastUpdate);
    const bActive = isVehicleActive(b.lastUpdate);
    
    if (aActive && !bActive) return 1;  // Active vehicles come last (higher z-index)
    if (!aActive && bActive) return -1; // Inactive vehicles come first (lower z-index)
    return 0;
  });
}
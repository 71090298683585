import { StopSign } from '../icons/StopSign';
import { BusStop } from '../../types/busStop';
import Tooltip from '../ui/Tooltip';

interface StopMarkerProps {
  stop: BusStop;
}

export default function StopMarker({ stop }: StopMarkerProps) {
  return (
    <div className="relative group cursor-pointer z-10">
      <Tooltip text={
        <div className="text-sm font-medium text-gray-900">
          <span className="font-semibold">{stop.name}</span>
        </div>
      }>
        <div className="relative">
          <div className="absolute -inset-2 bg-red-100/50 rounded-full blur-sm group-hover:bg-red-100 
                        transition-all duration-200"></div>
          <div className="bg-white rounded-full p-1.5 shadow-lg transform group-hover:scale-105 transition-all duration-300">
            <StopSign 
              className="h-5 w-5"
              style={{ color: '#DC2626' }} // Red-600 for stop signs
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}
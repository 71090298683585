import { PersonIcon } from '../icons/PersonIcon';
import CloseableTooltip from '../ui/CloseableTooltip';

export default function UserLocationMarker() {
  return (
    <div className="relative">
      <CloseableTooltip text="You are here!" defaultOpen>
        <div className="absolute -top-[28px] -left-[12px]">
          <div className="relative">
            <div className="absolute -inset-2 bg-blue-100/50 rounded-full blur-sm"></div>
            <div className="bg-blue-600 rounded-full p-2 shadow-lg transform hover:scale-105 transition-all duration-300">
              <PersonIcon 
                className="h-5 w-5 text-white"
              />
              {/* Pulsing effect */}
              <div className="absolute inset-0 rounded-full animate-ping bg-blue-400 opacity-75"></div>
            </div>
          </div>
        </div>
      </CloseableTooltip>
    </div>
  );
}
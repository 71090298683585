import { useState, useCallback, useEffect } from 'react';
import { Vehicle, VehicleLocation } from '../types/vehicle';
import { VEHICLES_INFO } from '../config/vehicles';
import { API_CONFIG } from '../config/api';
import { isVehicleActive } from '../utils/vehicleUtils';
import { fetchVehicleLocations } from '../services/api';
import { formatApiError } from '../utils/errorUtils';

export function useVehicles() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const updateVehicleLocations = useCallback(async () => {
    try {
      const locations = await fetchVehicleLocations();
      
      const updatedVehicles = locations
        .filter((location) => VEHICLES_INFO[location.carId])
        .map((location) => {
          const vehicleInfo = VEHICLES_INFO[location.carId];
          const active = isVehicleActive(location.event_time);
          
          return {
            id: location.carId,
            lat: location.lat,
            lng: location.lon,
            mph: active ? location.mph : 0,
            head: location.head,
            lastUpdate: location.event_time,
            ...vehicleInfo
          };
        });
      
      setVehicles(updatedVehicles);
      setError(null);
    } catch (err) {
      const errorMessage = formatApiError(err);
      setError(`Unable to fetch vehicle locations: ${errorMessage}`);
      console.error('Error fetching vehicle locations:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    updateVehicleLocations();
    const interval = setInterval(updateVehicleLocations, API_CONFIG.REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [updateVehicleLocations]);

  return { vehicles, error, isLoading };
}